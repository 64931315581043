import { defineStore } from 'pinia';
import type { Notification } from '~/types/app.types';

interface CreateNotificationArgs {
	type: 'error' | 'info' | 'success' | 'warning';
	message: string;
	autoClose?: boolean;
	duration?: number;
}

export const useNotificationStore = defineStore('NotificationStore', () => {
	const state = reactive({
		notifications: [] as Notification[],
	});

	function removeNotification(id: number) {
		state.notifications = state.notifications.filter((notification) => notification.id !== id);
	}

	function createNotification({
		type,
		message,
		autoClose = true,
		duration = 4000,
	}: CreateNotificationArgs) {
		const id = Date.now();
		const notifications = [
			{
				id,
				type,
				message,
			},
			...state.notifications,
		];

		state.notifications = notifications;

		if (autoClose) {
			setTimeout(() => {
				removeNotification(id);
			}, duration);
		}
	}

	return { state, removeNotification, createNotification };
});
