import { useNotificationStore } from '~/stores/notificationStore';
import * as Sentry from '@sentry/core';
import type { NuxtError } from 'nuxt/app';
import { FetchError } from 'ofetch';

function standardizeFetchError(error: unknown): Error {
	if (error instanceof Error) {
		return error;
	}

	if (typeof error === 'object' && error !== null) {
		// Error from useFetch onResponseError
		if ('_data' in error && error._data) {
			const nuxtError = error._data as NuxtError;
			return new Error(nuxtError.message || 'Unknown useFetch error', { cause: nuxtError });
		}
		// Error returned from $fetch
		if (error instanceof FetchError && 'data' in error) {
			const fetchErrorData = error.data as { message?: string; statusMessage?: string };
			return new Error(
				fetchErrorData?.message || fetchErrorData?.statusMessage || 'Unknown $fetch error',
				{ cause: error.stack },
			);
		}
	}

	return new Error('Unknown error type', { cause: error });
}

/**
 * Universal error handler for frontend async operations.
 *
 * This function performs the following operations:
 * - Standardizes errors. Accepts regular errors, $fetch errors, and onResponseError responses.
 * - Logs the given error to Sentry for all environments.
 * - If the environment is 'development', logs the error to the console as well.
 * - If a notification message is provided, creates a new error type notification.
 *
 * @param error - The error object that needs to be handled.
 * @param showNotification=true - A flag indicating whether to show a notification to the user.
 * @param notificationMessage='An unexpected error occurred.' - Optional fallback notification message.
 */
export default function handleFrontendError(
	error: unknown,
	showNotification: boolean = true,
	notificationMessage?: string,
) {
	const standardizedError = standardizeFetchError(error);

	Sentry.captureException(standardizedError);

	if (process.env.NODE_ENV === 'development') {
		console.error(standardizedError);
	}

	if (showNotification) {
		const notificationStore = useNotificationStore();
		const message =
			notificationMessage || standardizedError.message || 'An unexpected error occurred.';
		notificationStore.createNotification({
			type: 'error',
			message,
		});
	}
}
